.contentKinescope {
    padding-top: 56.25%;
    position: relative;
    display: block;
}

.kinescopePlayer {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
