@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.shapes {
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
}

.orangePolygon {
    position: absolute;
    top: -8vh;
    left: 16vw;
    width: 23vw;

    @include media-breakpoint-only(xs) {
        width: 23rem;
        top: -5rem;
        left: -9rem;
    }
}

.greenStick {
    position: absolute;
    top: -4vh;
    left: 9vw;
    width: 26vw;

    @include media-breakpoint-only(xs) {
        width: 25rem;
        top: -2.5rem;
        left: -8.5rem;
    }
}

.greenPolygon {
    position: absolute;
    width: 27vw;
    bottom: 1vh;
    left: -4vw;

    @include media-breakpoint-only(xs) {
        width: 22rem;
        bottom: 0;
        left: -3rem;
    }
}

.joystick {
    position: absolute;
    width: 40vw;
    bottom: -6vh;
    left: -15vw;

    @include media-breakpoint-only(xs) {
        width: 33rem;
        bottom: -5;
        left: -12rem;
    }
}

.purplePolygon {
    position: absolute;
    bottom: 6vh;
    right: -10vw;
    width: 38vw;

    @include media-breakpoint-only(xs) {
        width: 17rem;
        bottom: 23rem;
        right: -3rem;
    }
}

.orangeStick {
    position: absolute;
    bottom: -8vh;
    right: -17vw;
    width: 45vw;

    @include media-breakpoint-only(xs) {
        width: 24rem;
        bottom: 17rem;
        right: -8rem;
    }
}
