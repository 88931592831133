@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.infoLabel {
    width: fit-content;

    &.accent {
        @include themed() {
            background: themed-color('accent');
        }
    }
    &.success {
        @include themed() {
            background: themed-color('success');
        }
    }
}

.value {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: bold;
    padding: 0 0.5rem;

    @include themed() {
        color: themed-color('text');
    }
}
