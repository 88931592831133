.phone {
    font-weight: 600;
    white-space: nowrap;
}

.resendBtnWrap {
    display: flex;
    padding: 3rem 0 1rem 0;
}

.phoneEditBtn {
    margin-left: 2rem;
}
