@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.currentSubscriptionInfoWrap {
    width: 100%;
    padding: 0 2rem;
    margin-top: 4rem;

    @include themed() {
        border: themed-color('border') solid 2px;
    }
}

.currentSubscriptionInfoItem {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;

    @include themed() {
        border-bottom: themed-color('border') solid 2px;
    }
}

.currentSubscriptionInfoItemLabelWrap {
    width: 23rem;
}

.controllsWrap {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
}

.currentSubscriptionBtn {
    margin-right: 1.5rem;
}
