@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.posterSizeWrap {
    padding-bottom: 56.33%;
    position: relative;
}

.posterWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.background {
    @include themed() {
        background: themed-color('skeleton');
    }
}

.empty {
    @include themed() {
        background: themed-color('input');
    }
}

.poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: transparent;
}
