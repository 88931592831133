@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.container {
    min-height: calc(100vh - 7rem);
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-only(xs) {
        align-items: flex-start;
        padding-top: 13rem;
    }
}

.wrap {
    max-width: 56rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & * {
        z-index: 1;
    }

    @include media-breakpoint-only(xs) {
        max-width: 100%;
    }
}

.aboveHeading {
    margin-bottom: 6rem;
}

.heading {
    text-align: center;
    margin-bottom: 1rem;
}

.description {
    margin-bottom: 2rem;
}
