@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.title {
    height: 2.5rem;
    width: 26rem;
    @include media-breakpoint-up(sm) {
        height: 3rem;
        width: 30rem;
    }
}

.price {
    width: 21rem;
    height: 2rem;
    @include media-breakpoint-up(sm) {
        width: 17em;
        height: 1.5rem;
    }
}
