.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.fastActionPanelWrap {
    margin: 0.5rem 0 0 auto;
    padding-left: 1rem;
}
