@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints.scss';

.notificationItem {
    width: 100%;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 1rem;

    @include themed() {
        background: themed-color('input');
    }

    @include media-breakpoint-down(xs) {
        padding: 0.5rem 1rem;
    }
}

.notificationIcon {
    flex: none;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-top: 0.2rem;

    @include media-breakpoint-down(xs) {
        margin-top: 0.1rem;
    }
}

.notificationContent {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 1rem;
    line-height: 2rem;
}
