.quizFrame {
    width: 1201px;
    height: 818px;
    border-radius: 25px;
}

@media only screen and (max-width: 1350px) {
    .quizFrame {
        width: 961px;
        height: 755px;
    }
}

@media only screen and (max-width: 1080px) {
    .quizFrame {
        width: 598px;
        height: 1070px;
    }
}

@media only screen and (max-width: 640px) {
    .quizFrame {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
    }
}
