@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.avatar {
    position: relative;
    overflow: hidden;

    @include themed() {
        background: themed-color('border');
        color: themed-color('label');
    }

    &.auto {
        width: 100%;
        padding-bottom: 100%;
    }

    &.normal {
        width: 4rem;
        height: 4rem;
        padding-bottom: 0;
    }

    &.small {
        width: 3rem;
        height: 3rem;
        padding-bottom: 0;
    }
}

.image {
    position: absolute;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.firstLetter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;

    @include themed() {
        color: themed-color('icon');
    }
}
