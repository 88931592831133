@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints.scss';

.cardListGridLayout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6rem 3rem;

    @include media-breakpoint-only(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(xs) {
        grid-template-columns: 1fr;
    }
}
