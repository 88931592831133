@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.informer {
    width: 100%;
    padding: 2rem;

    &.compact {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1.5rem;

        .buttons {
            margin: 0 !important;
        }
    }

    &.accent {
        @include themed() {
            border: themed-color('accent', 'dark') solid 1px;
        }
    }

    &.warning {
        @include themed() {
            border: themed-color('warning', 'dark') solid 1px;
        }
    }

    &.success {
        @include themed() {
            border: themed-color('success', 'dark') solid 1px;
        }
    }

    &.fail {
        @include themed() {
            border: themed-color('fail', 'dark') solid 1px;
        }
    }
}
.buttons {
    display: flex;
    margin: 0 -0.5rem;
    * {
        margin: 0 0.5rem;
    }
}
