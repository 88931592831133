@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints.scss';

.container {
    display: flex;
    align-items: center;
}

.backLink {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
