@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

.multiInputsField {
    display: flex;
    flex-direction: row;

    input {
        width: 4rem;
        height: 6rem;
        text-align: center;
        border: none;
        border-radius: 0;
        margin: 0 8px;
        outline: none;

        @include text-size-regular();
        @include themed() {
            color: themed-color('text');
            background-color: themed-color('input');
        }

        &:focus {
            outline: none;
        }
    }

    &.error {
        input {
            @include themed() {
                border: themed-color('fail') solid 1px !important;
            }
        }
    }
}
