@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.BadgePlacer {
    position: relative;
}

.badgePlacerItem {
    position: absolute;
    z-index: 1;
}

.topLeft {
    top: 0;
    left: 0;
}

.topRight {
    top: 0;
    right: 0;
}

.bottomRight {
    bottom: 0;
    right: 0;
}

.bottomLeft {
    bottom: 0;
    left: 0;
}
