.progress {
    width: 6rem;
    height: 6rem;
}

.linesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.line {
    margin-bottom: 0.5rem;
    width: 12rem;
    height: 2rem;
    &:last-child {
        width: 10rem;
    }
}
