@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.checkerAssignmentListItem {
    display: grid;
    grid-template-columns: repeat(9, 1fr) 40px repeat(2, 1fr);
    grid-gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 4px;
    transition: all 0.2s ease;
    text-decoration: none;

    @include themed() {
        border: themed-color('border') solid 1px;
    }

    &:hover {
        @include themed() {
            transition: all 0.2s ease;
            background: themed-color('text-inverted');
        }
    }

    & > *:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    & > *:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 3;
    }

    & > *:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 10;
    }

    & > *:nth-child(4) {
        grid-column-start: 10;
        grid-column-end: 11;
    }

    & > *:nth-child(5) {
        grid-column-start: 11;
        grid-column-end: 13;
    }
}

.infoIconsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nestingList {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;

    > * {
        position: relative;
        padding-right: 3rem;

        &:after {
            position: absolute;
            content: '';
            right: ((30px - 8px) / 2);
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;

            @include themed() {
                border-left: 6px solid themed-color('icon');
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.userWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userAvatarWrap {
    width: 3rem;
    margin-right: 1rem;
}

.userName {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.keyValueWrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
        &:first-child {
            width: 6rem;
        }
    }
}

.success {
    @include themed() {
        color: themed-color('success');
    }
}

.attention {
    @include themed() {
        color: themed-color('notification-warning', 'light');
    }
}

.warning {
    @include themed() {
        color: themed-color('warning');
    }
}

.fail {
    @include themed() {
        color: themed-color('fail');
    }
}
