@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.tariffItemCol {
    @include media-breakpoint-only(xs) {
        margin-bottom: 6rem;

        &:last-child {
            margin-bottom: 0rem;
        }
    }
}

.successStateBtn {
    @include media-breakpoint-only(xs) {
        width: 100%;
    }
}
