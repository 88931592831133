.popover {
    position: relative;
    width: fit-content;
    height: fit-content;

    &:hover {
        .popoverContent {
            opacity: 1;
            visibility: visible;
            transition: all 0.2s ease;
        }
    }
}

.popoverTriggerWrap {
    cursor: pointer;
}

.popoverContent {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition: all 0.2s ease;

    &_left {
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &_right {
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    &_top {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &_bottom {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &_topLeft {
        bottom: 100%;
        right: 100%;
    }

    &_topRight {
        bottom: 100%;
        left: 100%;
    }

    &_bottomLeft {
        top: 100%;
        right: 100%;
    }

    &_bottomRight {
        top: 100%;
        left: 100%;
    }
}
