.contetntCard {
    height: 100%;
}

.title {
    margin-bottom: 1rem;
    word-break: break-word;
}

.label {
    white-space: nowrap;
}
