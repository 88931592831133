@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.leftSideContentDesktop {
    position: sticky;
    top: 130px;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.leftSideContentMobile {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}
