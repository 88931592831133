@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.Tab {
    display: flex;
    flex-grow: 1;

    @include media-breakpoint-down(xs) {
        justify-content: space-between;

        @include themed() {
            border-bottom: 2px solid themed-color('border');
        }
    }
}

.TabItem {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 2rem;
    cursor: pointer;
    @include themed() {
        border-bottom: 2px solid themed-color('border');
    }

    @include media-breakpoint-down(xs) {
        width: auto;
        margin-bottom: -2px;
        padding-bottom: 1.4rem;
    }

    &_Active {
        @include themed() {
            border-bottom: 2px solid themed-color('accent');
        }
    }
}
