@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.authPageLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 9rem 0;

    @include media-breakpoint-down(xs) {
        padding: 0 15px 9rem 15px;
    }
}

.authHeading {
    text-align: center;
    white-space: pre-wrap;
}

.authPageContent {
    max-width: 39rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;

    @include media-breakpoint-down(xs) {
        max-width: 100%;
    }
}

.authPageDescription {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0 0 0;
    text-align: center;
}

.authPageImg {
    width: 20rem;
    margin: 1.5rem 0;

    @include media-breakpoint-down(xs) {
        width: 12rem;
    }
}
