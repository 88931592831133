@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.cardLayout {
    width: 100%;

    @include themed() {
        border: themed-color('border') solid 1px;
    }

    &.isEmptyChildren {
        border: none;
    }
}

.thumbnailWrap {
    position: relative;
    overflow: hidden;
}

.thumbnailSub {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.labelsWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    padding: 2rem;
    pointer-events: none;

    &.topStart {
        > * {
            margin-right: 1rem;
        }
    }

    &.topEnd {
        justify-content: end;

        > * {
            margin-left: 1rem;
        }
    }

    @include media-breakpoint-only(xs) {
        padding: 1.5rem 0 0 1.5rem;
    }
}

.contentWrap {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;

    @include media-breakpoint-only(xs) {
        padding: 1.5rem;
    }
}
