@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.radioButton {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    height: 4rem;
    width: fit-content;
    padding: 0 2rem;

    @include themed() {
        border: 2px solid themed-color('border');
    }
}

.isFullWidth {
    width: 100%;
}

.nativeRadio {
    opacity: 0;
    outline: none;
    position: absolute;
    z-index: -1;
}

.checked {
    border-width: 2px;

    @include themed() {
        border-color: themed-color('accent');
    }
}
