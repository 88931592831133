.sectionItem {
    display: flex;
    align-items: flex-start;
}

.title {
    display: flex;
    align-items: center;
}

.progress {
    margin-right: 2rem;
    margin-top: 2.5rem;
}
