@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints.scss';

.bg {
    padding-bottom: 2rem;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(xs) {
        background: none !important;
    }
}
