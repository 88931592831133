.DefinitionList {
    display: grid;
    grid-column-gap: 1rem;
    justify-items: start;
    grid-row-gap: 0;
    grid-template-columns: min-content minmax(min-content, 1fr);
}

.label {
    padding: 0;
    width: 100%;
    white-space: nowrap;
}

.value {
    padding: 0;
    white-space: normal;
}
