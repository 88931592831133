@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.enrollmentProgress {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @include media-breakpoint-only(xs) {
        flex-direction: column-reverse;
    }
}

.enrollmentProgressBars {
    width: 26rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-only(sm) {
        width: 22rem;
    }

    @include media-breakpoint-only(xs) {
        width: 100%;
    }
}

.enrollmentProgressInfo {
    margin-bottom: -0.5rem;

    @include media-breakpoint-only(sm) {
        display: block;
    }

    @include media-breakpoint-only(xs) {
        margin-bottom: 0;
    }
}
