.row {
    display: flex;
    flex-direction: row;
}

.imageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 19rem;
    width: 19rem;
    height: 12rem;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    overflow: hidden;
}

.icon {
    margin-left: auto;
}
