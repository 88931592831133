@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.title {
    height: 3.5rem;
    width: 22rem;
    @include media-breakpoint-up(sm) {
        height: 5rem;
        width: 32rem;
    }
}

.card {
    max-width: 41rem;
}
