@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.discountBadge {
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgShape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.bgShapePath {
    &.accent {
        @include themed() {
            fill: themed-color('accent');
        }
    }
    &.success {
        @include themed() {
            fill: themed-color('success');
        }
    }
}

.value {
    position: relative;
    z-index: 1;
    transform: rotate(23deg);
    font-weight: bold;
}
