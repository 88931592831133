@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

.progressLayoutHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 0.5rem 0;
}

.progressLayoutLabelWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.progressLayoutLabel {
    margin-right: 0.5rem;
}

.progressLayoutPopover {
    @include media-breakpoint-only(xs) {
        display: none;
    }
}

.progressLayoutPopoverContent {
    width: 31rem;
    padding: 1.5rem;

    @include themed() {
        background: themed-color('input');
    }
}
