@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/easings';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

:global {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s;

        @include themed() {
            -webkit-text-fill-color: themed-color('text');
        }
    }

    .special-label {
        background: transparent !important;
        display: block !important;
        top: 7px !important;

        @include themed() {
            color: themed-color('label');
        }

        @include media-breakpoint-down(sm) {
            top: 5px !important;
        }
    }

    .country.highlight,
    .country:hover,
    .selected-flag:hover,
    .selected-flag:focus,
    .selected-flag.open {
        @include themed() {
            border-radius: 0 !important;
            background: themed-color('border') !important;
        }
    }

    .react-tel-input .selected-flag .arrow {
        @include themed() {
            border-top: 4px solid themed-color('text');
        }
    }

    .react-tel-input .selected-flag .arrow.up {
        @include themed() {
            margin-top: -5px;
            border-top: 4px solid transparent;
            border-bottom: 4px solid themed-color('text');
        }
    }
}

.inputWrap {
    height: 6rem;
    position: relative;

    @include themed() {
        background-color: themed-color('input');
    }

    @include media-breakpoint-down(sm) {
        height: 5rem;
    }
}

.btn {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border: none !important;
    border-radius: 0 !important;

    @include themed() {
        background-color: themed-color('input');
    }
}

.input {
    height: 100% !important;
    width: 100% !important;
    border: none !important;
    border-radius: 0 !important;
    outline: none;
    box-sizing: border-box;
    padding: 2.1rem 1.5rem 0.5rem;
    height: 100%;
    font-weight: normal;

    @include themed() {
        background-color: themed-color('input');
    }

    @include text-size-regular();

    @include media-breakpoint-up(sm) {
        padding: 2.4rem 2rem 0.6rem 2rem;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.dropdown {
    margin-top: 3px !important;

    @include themed() {
        color: themed-color('text');
        background-color: themed-color('input');
    }
}
