@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.pageAgreementWrap {
    width: 100%;
    max-width: 84rem;
    margin: 0 auto;
    padding: 20rem 0 13rem 0;

    @include media-breakpoint-down(sm) {
        padding: 10rem 4rem 10rem 4rem;
    }

    @include media-breakpoint-down(xs) {
        padding: 5rem 1.5rem 5rem 1.5rem;
    }
}

.editorContentWrap {
    padding-bottom: 3rem;
}

.editorContentItem {
    width: 100%;
    padding-top: 3rem;

    @include themed() {
        border-bottom: themed-color('border') solid 2px;
    }

    &:last-of-type {
        border: none;
    }
}

.editorContentItemDate {
    margin-bottom: 1rem;
}
