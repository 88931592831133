.breadcrumbsWrap {
    display: flex;
    justify-content: space-between;
}

.coursesWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 0.5rem;
}

.coursesWrap > * {
    margin-right: 1.5rem;

    &:after {
        content: ',';
    }

    &:last-child {
        &:after {
            content: '';
        }
    }
}

.headingWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > h1 {
        max-width: calc(100% - 180px);
    }
}

.videoWrap {
    margin-top: 3rem;
}
