@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.progressBar {
    width: 100%;
    height: 7px;

    @include themed() {
        background: themed-color('border');
    }
}

.progressLine {
    height: 7px;

    &_accent {
        @include themed() {
            background: themed-color('accent');
        }
    }

    &_success {
        @include themed() {
            background: themed-color('success');
        }
    }

    &_warning {
        @include themed() {
            background: themed-color('warning');
        }
    }

    &_fail {
        @include themed() {
            background: themed-color('fail');
        }
    }
}
