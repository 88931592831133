@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.Progress {
    transform: rotateZ(-90deg);

    &_bg {
        @include themed() {
            fill: themed-color('progress-background');
        }

        &_active {
            @include themed() {
                stroke: themed-color('accent');
            }
        }
    }

    &_maskBg {
        @include themed() {
            fill: #fff;
        }

        &_active {
            @include themed() {
                stroke: themed-color('accent');
            }
        }
    }

    // sizes
    &_size {
        &_normal {
            width: 4rem;
            height: 4rem;
            @include media-breakpoint-only(xs) {
                width: 3rem;
                height: 3rem;
            }
        }

        &_large {
            width: 6rem;
            height: 6rem;
            @include media-breakpoint-only(xs) {
                width: 5rem;
                height: 5rem;
            }
        }
    }
}
