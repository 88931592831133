@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.PageProfileLayout {
    padding-top: 6rem;
    padding-bottom: 20rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2rem;

    @include media-breakpoint-down(sm) {
        padding-top: 4rem;
        padding-bottom: 8rem;
        grid-template-columns: repeat(4, 1fr);
    }
}

.avatarWrap {
    grid-column-start: 1;
    grid-column-end: 3;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.contentWrap {
    grid-column-start: 4;
    grid-column-end: 10;

    @include media-breakpoint-down(sm) {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}
