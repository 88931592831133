@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';

.MobileBottomBar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem 1.5rem;
    transform: translateY(0%);
    z-index: 80;
    transition: transform 0.2s ease;
    box-sizing: border-box;

    @include themed() {
        background: themed-color('background');
        border-top: themed-color('border') solid 2px;
    }

    @media (min-height: 812px) {
        padding-bottom: 4rem;
    }

    &_hidden {
        transform: translateY(100%);
        transition: transform 0.2s ease;
    }
}
