@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.discordWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @include themed() {
        border-top: themed-color('border') solid 2px;
        border-bottom: themed-color('border') solid 2px;
    }
}

.discordUserWrap {
    display: flex;
    align-items: center;
}

.discordAvatar {
    margin-right: 1.5rem;
}

.discordButton {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    border: none;
    cursor: pointer;
    padding: 1.8rem 2rem;
    transition: background 0.2s ease;
    text-decoration: none;

    @include themed() {
        background: themed-color('input');
    }

    &:hover {
        transition: background 0.2s ease;

        @include themed() {
            background: themed-color('border');
        }
    }
}

.discordButtonIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 2rem;

    @include themed() {
        background: themed-color('icon');
    }
}
