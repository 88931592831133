@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.contactsFormIcon {
    width: 28rem;
}
.childrenWrap {
    width: 100%;
}
.skipBtn {
    margin-top: 3rem;
    font-size: 1.8rem !important; //@todo: create local Button component with correct sizes

    @include media-breakpoint-only(xs) {
        font-size: 1.6rem !important;
    }
}
.agreementWrap {
    text-align: center;
    padding: 3rem 0 12rem 0;

    @include media-breakpoint-only(xs) {
        padding: 3rem 0 3rem 0;
    }
}
