@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.CardLong {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 2rem;
    padding: 2rem 0;

    @include media-breakpoint-only(lg) {
        grid-template-columns: repeat(12, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include themed() {
        border: themed-color('border') solid 1px;
    }
}

.imageContainer {
    position: relative;
    padding-left: 2rem;
    grid-column-start: 1;
    grid-column-end: 4;

    @include media-breakpoint-only(lg) {
        grid-column-end: 5;
    }

    @include media-breakpoint-only(sm) {
        grid-column-end: 3;
    }

    @include media-breakpoint-only(xs) {
        padding-right: 2rem;
        grid-column-end: 5;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column-start: 4;
    grid-column-end: 9;

    @include media-breakpoint-only(lg) {
        grid-column-start: 5;
        grid-column-end: 12;
    }

    @include media-breakpoint-only(sm) {
        grid-column-start: 3;
        grid-column-end: 5;
        padding-right: 2rem;
    }

    @include media-breakpoint-only(xs) {
        padding: 0 2rem;
        grid-column-start: 1;
        grid-column-end: 5;
    }
}

.topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.infoContent {
    display: flex;
    flex-direction: column;
}

.description {
    margin-top: 1rem;

    @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
    }
}

.footer {
    display: flex;
    align-items: center;
}

.arrowIcon {
    @include media-breakpoint-only(xs) {
        display: none;
    }
}
