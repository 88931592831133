.NotificationListWrapper {
    position: absolute;
    width: 100%;
}

.headerStaffBtn {
    margin-left: 3rem;
}

.footerWrap {
    z-index: 1;
}
