.modalContainer {
    width: 53rem;
    padding: 6rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
        text-align: center;
        white-space: pre-line;
    }
}

.modalDescription {
    margin: 1.5rem 0 3rem 0;

    b {
        font-weight: 600;
    }
}

.resendWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
}

.countDownWrap {
    width: 2rem;
    margin-left: 0.5rem;
}
