@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';

.input {
    margin-bottom: 1.5rem;
}

.agreementsWrap {
    padding-top: 2rem;
}

.agreementCheckbox {
    margin-bottom: 1rem;
}

.agreementLink {
    @include themed() {
        color: themed-color('accent');
    }
}

.discordDescription {
    font-size: 1.6rem;
    margin: 4.5rem 0 1.5rem 0;
}
