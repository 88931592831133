@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.row {
    display: flex;
}

.imageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19rem;
    height: 12rem;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
        max-width: 14rem;
        max-height: 9rem;
    }
    @include media-breakpoint-down(xs) {
        max-width: 5rem;
        max-height: 3rem;
    }
}

.image {
    width: 100%;
}

.text {
    flex-grow: 1;
    margin-right: 9rem;
    max-width: 46rem;
    @include media-breakpoint-down(sm) {
        margin-right: 7rem;
        max-width: 30rem;
    }
}

.lectureStatus {
    margin-right: 3rem;
    @include media-breakpoint-down(xs) {
        margin-right: 1rem;
    }
}

.icon {
    margin-left: auto;
}

.mobileHeader {
    display: flex;
    margin-bottom: 1rem;
}
