@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';

.headingWrap {
    padding: 2.4rem 2rem;

    @include themed() {
        border: 1px solid themed-color('border');
    }

    @include media-breakpoint-only(xs) {
        padding: 1.7rem 2rem;
    }
}

.childrenWrap > * {
    margin-top: -1px;
}
