@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

$logoSize: 8rem;

.header {
    position: relative;

    @include themed() {
        background: themed-color('background');
    }

    &:after {
        content: '';
        position: absolute;
        left: $logoSize;
        bottom: 0;
        width: calc(100% - #{$logoSize});
        height: 2px;

        @include themed() {
            background: themed-color('border');
        }
    }
}

.logoLink {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo {
    width: $logoSize;
    height: $logoSize;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;

    @include themed() {
        background: themed-color('text');
        color: themed-color('text-inverted');
    }
}

.logoIcon {
    width: 4rem;
    height: 4rem;
}
