@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

/* spacing between list items */
$itemTopSpacing: 3rem;
/* dash top spacing from the top of list item */
$itemDashTopSpacing: 1.5rem;

.listItemWrap {
    position: relative;
    padding-left: 2.3rem;
    padding-top: $itemTopSpacing;

    &:before {
        position: absolute;
        content: '';
        top: $itemTopSpacing + $itemDashTopSpacing;
        left: 0;
        width: 1.4rem;
        height: 2px;

        @include themed() {
            background: themed-color('border');
        }
    }

    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;

        @include themed() {
            background: themed-color('border');
        }
    }

    &:last-of-type {
        &:after {
            height: $itemTopSpacing + $itemDashTopSpacing;
        }
    }
}
