@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.sidebarLink {
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(sm) {
        height: auto;
        width: 4rem;
        max-width: 4rem;
        width: fit-content;
        flex-direction: column;
        justify-content: center;
    }
}

.sidebarLinkActive {
    svg,
    p {
        @include themed() {
            color: themed-color('accent') !important;
        }
    }
}

.sidebarLinkIcon {
    margin-right: 1.5rem;

    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }
}

.sidebarLinkLabelWrap {
    margin-left: auto;
    padding-left: 1.5rem;

    @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 0;
    }
}

.sidebarLinkContainer {
    top: 14rem;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 6rem;

    @include media-breakpoint-down(lg) {
        flex-direction: row;
        justify-content: space-between;
        margin-right: 0;
        margin-bottom: 6rem;
    }

    @include media-breakpoint-down(sm) {
        justify-content: space-evenly;
        margin-bottom: 0;
    }
}

.sidebarLinkWrapper {
    @include media-breakpoint-down(lg) {
        flex-grow: 1;
    }

    @include media-breakpoint-down(sm) {
        flex-grow: initial;
    }
}

.sidebarLinkWrapper:not(:last-child) {
    @include media-breakpoint-up(xl) {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
        @include themed() {
            border-bottom: 1px solid themed-color('border');
        }
    }
}

.sidebarLinkText {
    @include media-breakpoint-down(sm) {
        white-space: nowrap;
    }
}
