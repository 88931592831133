.CardShort {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .imageContainer {
        position: relative;
    }
}
