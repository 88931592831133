@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/_colors.scss';

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    // Всегда показываем скролл бар, так как у нас сайт в основном длинный
    // Что бы страница не дергалась туда-суда, во время загрузки
    overflow-y: scroll;
    @include themed() {
        background-color: themed-color('background');
    }
}
