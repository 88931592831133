@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loadingSpinner {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 1000px;
    @include theme('dark') {
        background: rgba(0, 0, 0, 0.1);
    }
    background: rgba(255, 255, 255, 0.1);
    @include theme('light') {
        background: rgba(255, 255, 255, 0.1);
    }

    &:after {
        transform-origin: 0 center;
        position: absolute;
        width: 50%;
        left: 50%;
        height: 100%;
        display: block;
        content: '';
        animation: rotation 1s linear infinite normal forwards;
        border-radius: 0px 1000px 1000px 0px;

        @include theme('dark') {
            background: linear-gradient(180deg, themed-color('accent', 'dark') 15%, rgba(0, 0, 0, 0.1) 85%);
        }
        background: linear-gradient(180deg, themed-color('accent', 'light') 15%, rgba(255, 255, 255, 0.1) 85%);
        @include theme('light') {
            background: linear-gradient(180deg, themed-color('accent', 'light') 15%, rgba(255, 255, 255, 0.1) 85%);
        }
    }
    -webkit-mask: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0L17.0711 2.92893L20 10L17.0711 17.0711L10 20L2.92893 17.0711L0 10L2.92893 2.92893L10 0ZM4.99542 4.99542L10 2.92246L15.0046 4.99542L17.0775 10L15.0046 15.0046L10 17.0775L4.99542 15.0046L2.92246 10L4.99542 4.99542Z" /></svg>')
        center/contain;
    mask: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0L17.0711 2.92893L20 10L17.0711 17.0711L10 20L2.92893 17.0711L0 10L2.92893 2.92893L10 0ZM4.99542 4.99542L10 2.92246L15.0046 4.99542L17.0775 10L15.0046 15.0046L10 17.0775L4.99542 15.0046L2.92246 10L4.99542 4.99542Z" /></svg>')
        center/contain;
}
