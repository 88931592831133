@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/easings';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.modalWrap,
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
}

.modalWrap {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
}

.overlay {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(14px);
    opacity: 1;
}

.body {
    margin: auto;
    padding: 40px 20px;
    flex: 0 1 auto;
    box-sizing: border-box;
    z-index: 100;

    @include media-breakpoint-down(sm) {
        height: 100%;
        padding: 0;
    }
}

.contentWrap {
    max-height: 100%;
    margin: 0 auto;
    position: relative;

    @include media-breakpoint-down(sm) {
        height: 100% !important;
        width: 100% !important;
    }
}

.content {
    max-height: 100%;
    box-sizing: border-box;
    overflow: auto;
    user-select: text;

    @include media-breakpoint-down(sm) {
        min-height: 100%;
    }

    @include themed() {
        background-color: themed-color('background');
    }
}

.closeButton {
    position: absolute;
    top: 0;
    left: calc(100% + 2rem);
    z-index: 101;

    @include media-breakpoint-down(sm) {
        left: unset;
        right: 2rem;
        top: 2rem;
    }
}
