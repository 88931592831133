@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.tariffItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 3rem;

    @include themed() {
        border: themed-color('border') solid 1px;
        background: themed-color('text-inverted');
    }

    &.isHighlighted {
        @include themed() {
            border: themed-color('success') solid 1px;
        }
    }
}

.discountBadgeWrap {
    position: absolute;
    top: 0;
    right: 3rem;
    transform: translateY(-50%);
}

.headingWrap {
    display: flex;
    align-items: flex-end;
}

.heading {
    margin-right: 1rem;
}

.labelWrap {
    height: 6rem;
}

.priceWrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.price {
    text-decoration: line-through;
    margin-left: 0.8rem;
}

.info {
    margin-top: 2.5rem;
}
