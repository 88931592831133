@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';

.title {
    height: 2.5rem;
    width: 26rem;
    @include media-breakpoint-up(sm) {
        height: 3rem;
        width: 26rem;
    }
}

.description {
    width: 17rem;
    height: 1.5rem;
    @include media-breakpoint-up(sm) {
        width: 12rem;
        height: 2rem;
    }
}

.footer {
    display: flex;
}
