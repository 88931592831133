.filters {
    margin-bottom: 3rem;
}

.categorySelectorWrap {
    display: flex;
    flex-wrap: wrap;

    > * {
        margin: 0 1rem 1rem 0;
    }
}
