.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4rem 0;

    > * {
        margin: 0 1.5rem;
    }
}
