@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/breakpoints';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/easings';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';

@mixin labelActive {
    transform: translateY(-0.8rem) scale(0.75);
    @include media-breakpoint-up(sm) {
        transform: translateY(-1rem) scale(0.8);
    }
}

.inputWrap {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.label {
    position: absolute;
    top: 1.3rem;
    left: 1.5rem;
    pointer-events: none;
    will-change: transform;
    transform-origin: top left;
    transition: transform $easeOutCubic $durNorm;

    font-weight: normal;
    @include text-size-regular();

    @include themed() {
        color: themed-color('label');
    }

    @include media-breakpoint-up(sm) {
        top: 1.5rem;
        left: 2rem;
    }
}

.input {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 2.1rem 1.5rem 0.5rem;
    resize: none;
    -webkit-appearance: none;
    border-radius: 0;

    font-weight: normal;
    @include text-size-regular();

    @include themed() {
        background-color: themed-color('input');
    }

    @include media-breakpoint-up(sm) {
        padding: 2.4rem 2rem 0.6rem 2rem;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &:focus + .label {
        @include labelActive();
    }

    &:-webkit-autofill + .label {
        @include labelActive();
    }

    &.isFilled + .label {
        @include labelActive();
    }

    &.noLabel {
        padding: 1.3rem 1.5rem 1.3rem;

        @include media-breakpoint-up(sm) {
            padding: 1.5rem 2rem 1.5rem 2rem;
        }
    }
}
