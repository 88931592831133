@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/typography';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/easings';

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.checkboxWrap {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.disabled,
    &.readonly {
        @include text-color-label();
    }
}

.checkboxControl {
    margin-right: 1rem;
    position: relative;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.checkboxInput,
.checkboxBox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.checkboxBox {
    @include focus-outline-unfocused();
    transition: outline-color ease $durMed, border ease $durMed, background-color ease $durMed;
    @include themed() {
        border: 2px solid themed-color('border');
        background-color: themed-color('background');
    }
}

.checkboxBox_error {
    @include themed() {
        border-color: themed-color('fail');
    }
}

.checkboxIcon {
    display: block;
    position: absolute;
    top: -2px; // Negativate borders
    left: -2px;
    opacity: 0;
    transform: scale(0);
    transition: transform $easeOutBack $durNorm, opacity ease $durMed;
}

.checkboxInput:focus-visible + .checkboxBox {
    @include focus-outline-focused();
}

.checkboxInput:checked + .checkboxBox {
    @include themed() {
        border: 2px solid themed-color('accent');
        background-color: themed-color('accent');
    }

    & .checkboxIcon {
        opacity: 1;
        transform: scale(1);
    }
}

.errorMessage {
    position: absolute;
    top: calc(100% + 0.2rem);
    left: 0;
    white-space: nowrap;
}
