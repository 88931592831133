@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/mixins';
@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors.scss';

.CollapseItemLayout {
    width: 100%;

    @include themed() {
        border-top: themed-color('border') solid 2px;

        &:last-of-type {
            border-bottom: themed-color('border') solid 2px;
        }
    }
}

.titleRow {
    @include button();
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title {
    max-width: calc(100% - 4rem);
    overflow: hidden;
    text-align: left;
}

.caretIcon {
    transform: rotate(180deg);

    &_collapsed {
        transform: rotate(0deg);
    }
}
