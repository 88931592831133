@import '~@xyz-school/xyz-react-frontend/lib/shared/styles/colors';

.CardsLayout {
    &__head {
        display: flex;
        align-items: center;
    }

    &__title {
        span {
            @include themed() {
                color: themed-color('label');
            }
        }
    }
}
